<template>
  <div class="page" id="survey-calls-list">
    <data-table
      ref="callsTable"
      :data="CDRs"
      :fields="translatedTableFields"
      :pagination="pagination"
      :loading="CDRsLoading"
      @changePage="onPageChange"
    >
      <template v-slot:agent="{ row }">
        <p v-if="row.agent">{{ row.agent.name }} ({{ row.agent.ext }})</p>
        <p v-else>{{ row.ext }}</p>
      </template>

      <template v-slot:status="{ row }">
        <b-badge
          class="px-3 py-2"
          :style="{
            backgroundColor: cdrsStatusColors[row.status] || '#ccc'
          }"
        >
          {{ $t(`call-survey.survey-cdrs.statusOptions.${row.status}`) }}
        </b-badge>
      </template>
      <template v-slot:completionStatus="{ row }">
        <b-badge
          class="px-3 py-2"
          :style="{
            backgroundColor:
              cdrsCompletionStatusColors[row.completionStatus] || '#ccc'
          }"
        >
          {{
            $t(
              `call-survey.survey-cdrs.completionStatusOptions.${row.completionStatus}`
            )
          }}
        </b-badge>
      </template>
      <template v-slot:createdAt="{ row }">
        <p>{{ row.createdAt | formatDate }}</p>
      </template>
      <template v-slot:survey="{ row }">
        <router-link
          :to="{ name: 'survey-details', params: { id: row.survey.id } }"
        >
          <p>{{ row.survey ? row.survey.name : '-' }}</p>
        </router-link>
      </template>
      <template v-slot:actions="{ row }">
        <button
          class="btn px-3 btn-sm btn-primary"
          @click="
            showAnswersModal = true
            currentCDR = row
          "
        >
          {{ $t('call-survey.survey-cdrs.table.answers') }}
        </button>
      </template>
    </data-table>

    <b-modal
      :title="$t('call-survey.survey-cdrs.modals.answers.title')"
      v-model="showAnswersModal"
      scrollable
      content-class="overflow-auto"
      hide-footer
      centered
      ignore-enforce-focus-selector="textarea, button, input"
      :dir="dir"
    >
      <div v-if="currentCDR && currentCDR.answers.length > 0">
        <div
          class="answer mb-3 card p-2"
          v-for="(answer, answerIdx) in currentCDR.answers"
          :key="answerIdx"
        >
          <h5 class="m-0 mb-1">
            {{ $t('call-survey.survey-cdrs.modals.answers.question') }}
            {{ answer.questionIndex + 1 }}
          </h5>
          <p class="m-0 mb-1">
            <b
              >{{
                $t('call-survey.survey-cdrs.modals.answers.attempt-number')
              }}: </b
            >{{ answer.attemptNumber }}
          </p>

          <div class="user-response">
            <p class="my-0">
              <small>
                {{ $t('call-survey.survey-cdrs.modals.answers.user-response') }}
                :</small
              >
            </p>
            <b-button
              v-if="answer.questionType === 'yes_no'"
              class="px-3 py-2 mb-2"
              variant="warning"
              :style="{
                color: 'white',
                backgroundColor:
                  parseInt(answer.userResponse) === 1 ? '#66BB6A' : '#FF6B6B'
              }"
            >
              {{ parseInt(answer.userResponse) === 1 ? 'Yes' : 'No' }}
            </b-button>

            <b-form-rating
              v-else-if="answer.questionType === 'one_five'"
              :value="+answer.userResponse"
              variant="warning"
              class="px-2 mb-2"
              readonly
              size="lg"
              inline
              show-value
            ></b-form-rating>

            <b-button
              v-else
              class="px-3 py-2 mb-2"
              variant="warning"
              :style="{
                color: 'white',
                backgroundColor: '#888'
              }"
            >
              {{ answer.userResponse }}
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import DataTable from '../../../elements/Table.vue'
import PostCallSurveyService from '../../../services/post-call-survey.service'
import moment from 'moment'

export default {
  components: {
    DataTable
  },
  data() {
    return {
      CDRs: [],
      CDRsLoading: false,
      pagination: {
        page: 1,
        perPage: 10,
        totalPages: 0
      },
      cdrsStatusColors: {
        'In-progress': '#66BB6A', // Fresh Green
        Completed: '#5C6BC0' // Rich Blue
      },
      cdrsCompletionStatusColors: {
        complete: '#66BB6A', // Fresh Green
        partial: '#0F6B6B', // Dark Blue
        'in-progress': '#FFC107', // Golden Yellow
        processing: '#FFB74D', // Vibrant Orange
        'no-response': '#FF6B6B' // Bright Red
      },
      fields: [
        {
          accessor: 'agent',
          header: 'post-call-survey.calls-list.fields.agent'
        },
        {
          accessor: 'phone',
          header: 'post-call-survey.calls-list.fields.phone'
        },
        {
          accessor: 'answeredQuestionsCount',
          header: 'post-call-survey.calls-list.fields.answeredQuestionsCount'
        },
        {
          accessor: 'completionStatus',
          header: 'post-call-survey.calls-list.fields.completionStatus'
        },
        {
          accessor: 'correctAnswersCount',
          header: 'post-call-survey.calls-list.fields.correctAnswersCount'
        },
        {
          accessor: 'duration',
          header: 'post-call-survey.calls-list.fields.duration'
        },
        {
          accessor: 'status',
          header: 'post-call-survey.calls-list.fields.status'
        },
        {
          accessor: 'createdAt',
          header: 'post-call-survey.calls-list.fields.createdAt'
        },
        {
          accessor: 'survey',
          header: 'post-call-survey.calls-list.fields.survey'
        },
        {
          accessor: 'actions',
          header: 'post-call-survey.calls-list.fields.actions'
        }
      ],
      showAnswersModal: false,
      currentCDR: null
    }
  },
  computed: {
    translatedTableFields() {
      return this.fields.map(field => ({
        ...field,
        header: this.$t(field.header)
      }))
    },
    dir() {
      return this.$i18n.locale() === 'ar' ? 'rtl' : 'ltr'
    }
  },
  methods: {
    onPageChange(page) {
      this.pagination.page = page
      this.fetchCDRs()
    },
    async fetchCDRs() {
      this.CDRsLoading = true
      try {
        const res = await PostCallSurveyService.getCDRs(
          this.pagination.page,
          this.pagination.perPage
        )
        this.CDRs = res.data.requests
        this.pagination.totalPages = res.data.totalPages
      } catch (error) {
        console.error(error)
      } finally {
        this.CDRsLoading = false
      }
    },
    toggleDetails(id) {
      this.$refs.callsTable.toggleDetails(id)
    }
  },
  mounted() {
    this.fetchCDRs()
  },
  filters: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>
