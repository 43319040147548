var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page",attrs:{"id":"survey-calls-list"}},[_c('data-table',{ref:"callsTable",attrs:{"data":_vm.CDRs,"fields":_vm.translatedTableFields,"pagination":_vm.pagination,"loading":_vm.CDRsLoading},on:{"changePage":_vm.onPageChange},scopedSlots:_vm._u([{key:"agent",fn:function({ row }){return [(row.agent)?_c('p',[_vm._v(_vm._s(row.agent.name)+" ("+_vm._s(row.agent.ext)+")")]):_c('p',[_vm._v(_vm._s(row.ext))])]}},{key:"status",fn:function({ row }){return [_c('b-badge',{staticClass:"px-3 py-2",style:({
          backgroundColor: _vm.cdrsStatusColors[row.status] || '#ccc'
        })},[_vm._v(" "+_vm._s(_vm.$t(`call-survey.survey-cdrs.statusOptions.${row.status}`))+" ")])]}},{key:"completionStatus",fn:function({ row }){return [_c('b-badge',{staticClass:"px-3 py-2",style:({
          backgroundColor:
            _vm.cdrsCompletionStatusColors[row.completionStatus] || '#ccc'
        })},[_vm._v(" "+_vm._s(_vm.$t( `call-survey.survey-cdrs.completionStatusOptions.${row.completionStatus}` ))+" ")])]}},{key:"createdAt",fn:function({ row }){return [_c('p',[_vm._v(_vm._s(_vm._f("formatDate")(row.createdAt)))])]}},{key:"survey",fn:function({ row }){return [_c('router-link',{attrs:{"to":{ name: 'survey-details', params: { id: row.survey.id } }}},[_c('p',[_vm._v(_vm._s(row.survey ? row.survey.name : '-'))])])]}},{key:"actions",fn:function({ row }){return [_c('button',{staticClass:"btn px-3 btn-sm btn-primary",on:{"click":function($event){_vm.showAnswersModal = true
          _vm.currentCDR = row}}},[_vm._v(" "+_vm._s(_vm.$t('call-survey.survey-cdrs.table.answers'))+" ")])]}}])}),_c('b-modal',{attrs:{"title":_vm.$t('call-survey.survey-cdrs.modals.answers.title'),"scrollable":"","content-class":"overflow-auto","hide-footer":"","centered":"","ignore-enforce-focus-selector":"textarea, button, input","dir":_vm.dir},model:{value:(_vm.showAnswersModal),callback:function ($$v) {_vm.showAnswersModal=$$v},expression:"showAnswersModal"}},[(_vm.currentCDR && _vm.currentCDR.answers.length > 0)?_c('div',_vm._l((_vm.currentCDR.answers),function(answer,answerIdx){return _c('div',{key:answerIdx,staticClass:"answer mb-3 card p-2"},[_c('h5',{staticClass:"m-0 mb-1"},[_vm._v(" "+_vm._s(_vm.$t('call-survey.survey-cdrs.modals.answers.question'))+" "+_vm._s(answer.questionIndex + 1)+" ")]),_c('p',{staticClass:"m-0 mb-1"},[_c('b',[_vm._v(_vm._s(_vm.$t('call-survey.survey-cdrs.modals.answers.attempt-number'))+": ")]),_vm._v(_vm._s(answer.attemptNumber)+" ")]),_c('div',{staticClass:"user-response"},[_c('p',{staticClass:"my-0"},[_c('small',[_vm._v(" "+_vm._s(_vm.$t('call-survey.survey-cdrs.modals.answers.user-response'))+" :")])]),(answer.questionType === 'yes_no')?_c('b-button',{staticClass:"px-3 py-2 mb-2",style:({
              color: 'white',
              backgroundColor:
                parseInt(answer.userResponse) === 1 ? '#66BB6A' : '#FF6B6B'
            }),attrs:{"variant":"warning"}},[_vm._v(" "+_vm._s(parseInt(answer.userResponse) === 1 ? 'Yes' : 'No')+" ")]):(answer.questionType === 'one_five')?_c('b-form-rating',{staticClass:"px-2 mb-2",attrs:{"value":+answer.userResponse,"variant":"warning","readonly":"","size":"lg","inline":"","show-value":""}}):_c('b-button',{staticClass:"px-3 py-2 mb-2",style:({
              color: 'white',
              backgroundColor: '#888'
            }),attrs:{"variant":"warning"}},[_vm._v(" "+_vm._s(answer.userResponse)+" ")])],1)])}),0):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }